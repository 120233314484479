import axios from "axios";
import qs from "qs";
import {Toast, Dialog} from 'vant';
// import gConf from 'gConf'

// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
const service = axios.create({
  // baseURL: gConf.BASE_API, 
  baseURL: 'https://www.junkoenergy.com/chunzi',
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 1500000, // request timeout
});

// request interceptor 请求拦截器
service.interceptors.request.use(
  (config) => {
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
    if (sessionStorage.getItem("token")) {
      // 让每个请求携带自定义token 请根据实际情况自行修改
      // config.headers['Authorization'] = storage.getToken()
      config.headers["token"] = sessionStorage.getItem("token");
    }
    // config.headers['Authorization'] = '888888888'
    // config.headers['token'] = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJ7XCJpZFwiOlwiMTQyNjkyMjYyMjQ0OTYwNjY2MVwiLFwibHhVc2VySWRcIjpcIjMwNjQwNjQtNVd1eHRqS0dsOGx0bzNtRGRkRkVsRDZNVmhsOWdcIixcIm5hbWVcIjpcIuadjuS6mlwiLFwibW9iaWxlXCI6XCJhSmNYL3U2NEFVLzloTEF4bXF6dzNZeDZtc2hmQkxYaFBydm5GMTJjMU5Dd2p6aUdRWGRHOGdWclpOUUw1aTNZRlA1aGtWQ3l3SllmT2RqUDdPTU5CV2Y4ejVhVndDbHhqd2FhT2RPL3B4YU52S3ZkRm9OdFNQc2dTSnJocExWQ05XVGZCY0dsak1iajRxMEVwdElmUCtVSmg2cGxqS2tjS2d4U0dHUkd6QTBcXHUwMDNkXCIsXCJlbWFpbFwiOlwiODUyMEAxNjMuY29tXCIsXCJlbXBsb3llZU51bWJlclwiOlwiMDAwMTJcIixcIm9yZ0lkXCI6XCIzMDY0MDY0LWFsdWxLaG1COGFhUmZxcGxuTExGQkJQRWNZZXlZelwiLFwib3JnTmFtZVwiOlwi6JOd5Zu-5a-55o6l5rWL6K-V57uE57uHXCIsXCJwYXJlbnRJZFwiOlwiMzA2NDA2NFwiLFwiYXZhdGFyUGF0aFwiOlwiXCIsXCJhdmF0YXJJZFwiOlwiXCIsXCJzdGF0dXNcIjpcIjFcIixcImFkbWluaXN0cmF0b3JzXCI6ZmFsc2V9IiwiZXhwIjoxNjI5Nzc0NjAxfQ.QikFI0bpNxDOhOI6ikFXvAculwff3tEtuKvZD_QeZZI'
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor 响应拦截器
service.interceptors.response.use(
  (response) => {
    // console.log('response>'+ JSON.stringify(response))
    if (response.headers.token) {
      //如果后台通过header返回token，说明token已经更新，则更新客户端本地token
      // store.dispatch('app/toggleToken',response.headers.token)
      sessionStorage.setItem("token", response.headers.token);
    }
    const res = response.data;

    response.config.method == "get";
    response.config.method == "post";

    if (res.code != 0) {
      //return Promise.reject(res.msg || 'error')
      Toast.clear();
      Dialog.alert({
        message: res.message,
      })
      return res;
    } else {
      //return Promise.reject(res.msg || 'error')
      // Toast('提示内容');
      return res;
    }
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          break;
        case 500:
          break;
        default:
          return Promise.reject(error.response.data.message);
      }
    }
  }
);

service.get = function(url, params) {
  return service({
    method: "get",
    url: url,
    params: params,
    transformRequest: function(params) {
      return qs.stringify(params, {
        arrayFormat: "repeat",
      });
    },
  });
};
service.post = function(url, params) {
  return service({
    method: "post",
    url: url,
    data: params,
    // transformRequest: function(params) {
    //   console.log(params);
    //   let aa = qs.stringify(params, {
    //     arrayFormat: 'repeat'
    //   })
    //   aa = JSON.parse(params);
    //   return aa
    //   // return JSON.parse(params)
    // }
  });
};
service.upload = function(url, header, params) {
  return service({
    method: "post",
    url: url,
    data: params,
    headers: header,
  });
};

export default service;

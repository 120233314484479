import axios from "axios";
import { API } from "./API";

export class APILogin extends API {
  // 获取详情
  static schoolTemplategetById(data) {
    return this.requestGet("/app/schoolTemplate/getById", data);
  }
  // 添加或修改
  static schoolStudentaddAndEdit(data) {
    return this.requestPost("/app/schoolStudent/addAndEdit", data);
  }

  // 获取产品列表数据
  static getChanList(data) {
    return this.requestPost("/app/product/getListPage", data);
  }

  // 查看详情
  static getDetailsApi(data) {
    return this.requestGet("/app/product/get", data);
  }

  // 提交留言
  static messageAdd(data) {
    return this.requestPost("/app/message/save", data);
  }


  // 获取案例列表数据
  static getAnliChanList(data) {
    return this.requestPost("/app/case/getListPage", data);
  }

  // 查看详情案例列表数据
  static getAnChanDetails(data) {
    return this.requestGet("/app/case/get", data);
  }








  // 根据蓝信code获取蓝信用户信息
  static login(data) {
    return this.requestGet("/exp/app/login/login", data);
  }
  // 获取token
  static getToken(data) {
    return this.requestGet("/exp/app/login/loginTest",data);
  }

  // 获取用户信息
  static getUser(data) {
    return this.requestGet("/exp/app/login/getUserInfo",data);
  }



  static getAuthCode(){
    let aapId = 101405;
    return axios.get(`http://domain/connect/oauth2/authorize?appid=${aapId}&redirect_uri=REDIRECT_URL&response_type=code&scope=snsapi_userinfo&state=1`)
  }

// 快递申请》》》》》》》》》》》》》》》》》》》》》
  // 寄件人信息新增
  static addAdress(data) {
    return this.requestPost("/exp/app/address/addAndEdit",data);
  }
  // 根据id获取详情
  static getByid(data) {
    return this.requestGet("/exp/app/address/getById",data);
  }
  // 根据类型获取取件列表
  static getListPage(data) {
    return this.requestPost("/exp/app/address/selectList",data);
  }
  // 删除
  static delArray(data) {
    return this.requestGet("/exp/app/address/del",data);
  }
  // 文件上传
  static addUpimg(data) {
    return this.requestPost("/exp/app/uploadNginx/uploadFileNginx",data);
  }
  // 根据申请人部门获取审批部门人员
  static getPeople(data) {
    return this.requestGet("/exp/app/approveConfig/selectApproveList",data);
  }
  // 添加邮寄申请信息
  static addAndApi(data) {
    return this.requestPost("/exp/app/apply/addAndEdit",data);
  }

// 快递列表》》》》》》》》》》》》》》》》》》》》》

static getShenList(data) {
  return this.requestPost("/exp/app/apply/getApplyListPage",data);
}
// 填写快递单号
static addOrderNum(data) {
  return this.requestGet("/exp/app/apply/fillInCourierNumber",data);
}
// 根据id获取详情
static getDetails(data) {
  return this.requestGet("/exp/app/apply/getById",data);
}

// 审批列表》》》》》》》》》》》》》》》》》》》》》

static getApprove(data) {
  return this.requestPost("/exp/app/apply/getApprovalListPage",data);
}
// 部门管理员审批
static bumenShen(data) {
  return this.requestPost("/exp/app/apply/deptApprove",data);
}

// 办公室审批
static bangongShen(data) {
  return this.requestPost("/exp/app/apply/officeApprove",data);
}


}

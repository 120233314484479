import request from '@/utils/request';

export class API {
  static getParmas(obj) {
    return Object.keys(obj)
      .map(function(item) {
        return item + "=" + obj[item];
      })
      .join("&");
  }
  static requestGet(url, data = {}) {
    let str = this.getParmas(data);
    str = str == "" ? "" : "?" + str;
    return request.get(`${url}${str}`);
  }
  static requestPost(url, data = {}) {
    return request.post(url, data);
  }
}
